<template>
  <div class="group relative flex items-center gap-8 py-3 leading-none">
    <div
      class="absolute left-2 top-1/2 h-full w-0.5 -translate-x-1/2 bg-gray-300 group-last:hidden dark:bg-gray-600"
    ></div>

    <div
      class="relative grid h-4 w-4 shrink-0 place-items-center rounded-full border-4 bg-white"
      :class="[
        level.active
          ? 'scale-150 border-primary'
          : 'border-gray-300 dark:border-gray-600',
      ]"
    ></div>

    <div class="grow space-y-1">
      <div class="font-bold">{{ level.title }}</div>
      <div v-if="description" class="text-sm leading-none text-gray-400">
        {{ description }}
      </div>
    </div>

    <div class="flex shrink-0 items-center gap-2 font-medium">
      Кэшбек
      <span class="font-bold">{{ level.percent }}%</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BonusesLevel } from '~/types/customer'

const { level } = defineProps<{
  level: BonusesLevel
}>()

const description = computed(() =>
  [level.from, level.to]
    .filter(isNotEmpty)
    .map((value) => money(value))
    .join(' - '),
)
</script>
